import { ReactNode } from "react";
import { atom } from "recoil";
import { FirebaseUser } from "src/firebase/auth/user";

export const modalComponent = atom<ReactNode>({
  key: "modalComponent",
  default: <></>,
});

export const modalSwitch = atom({
  key: "modalSwitch",
  default: false,
});

export const loginSwitch = atom<FirebaseUser | undefined>({
  key: "loginSwitch",
  default: undefined,
});
