import {
  AuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { firebaseAuth } from "src/firebase/firebase";

const providerTwitter = new TwitterAuthProvider();
const providerGoogle = new GoogleAuthProvider();

export const loginWithTwitter = async () => {
  return await handleLoginBase(providerTwitter);
};
export const loginWithGoogle = async () => {
  return handleLoginBase(providerGoogle);
};
export const handleLoginBase = async (provider: AuthProvider) => {
  return await signInWithPopup(firebaseAuth, provider);
  // .then((userCredential) => {
  //   const twitterId = userCredential?.user;
  //   const user = userCredential?.user;
  //   console.log("Twitter", twitterId, user, user?.photoURL);
  //   return true;
  // })
  // .catch((error) => {
  //   console.error("Error", error.message);
  //   return false;
  // });
};
