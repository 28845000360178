import { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ProblemPopupButton } from "src/components/pages/main/problem_popup_button";
import { Header } from "src/components/templates/header/header";
import { ProblemDefinition } from "src/core/problem";
import { PROBLEMS } from "src/data/problem_data";
import { loginSwitch } from "src/store/recoil_state";

export const MainPage = () => {
  const user = useRecoilValue(loginSwitch);
  const location = useLocation();

  // * this flag is used to catch the delay of login process.
  const [delayedNotLogInFlag, setDelayedNotLogInFlag] = useState(false);

  const problemByCategory = useMemo(
    () => aggregateProblemByCategory(PROBLEMS),
    []
  );

  // dealing with delay of login process.
  useEffect(() => {
    let newID: NodeJS.Timeout | undefined;
    if (!user && !delayedNotLogInFlag) {
      // set new timeout to redirect to login page.
      newID = setTimeout(() => {
        setDelayedNotLogInFlag(true);
      }, 3000);
    }
    return () => {
      if (newID) {
        console.debug("clearTimeout in cleanup.", newID);
        clearTimeout(newID);
      }
    };
  }, [delayedNotLogInFlag, user]);

  if (delayedNotLogInFlag) {
    return <Navigate replace to={`/login${location.search}`} />;
  }

  if (!user) {
    return <div>now authenticating...</div>;
  } else {
    return (
      <>
        <Header />
        {Object.keys(problemByCategory).map((category) => {
          return (
            <div
              key={category}
              style={{
                marginBottom: "10px",
                paddingLeft: "2vw",
                paddingRight: "2vw",
              }}
            >
              <h2>{category}</h2>
              {problemByCategory[category].map((problem) => {
                return (
                  <ProblemPopupButton problemID={problem.id} key={problem.id} />
                );
              })}
            </div>
          );
        })}
      </>
    );
  }
};

const aggregateProblemByCategory = (problems: typeof PROBLEMS) => {
  const result: { [key: string]: ProblemDefinition[] } = {};
  problems.forEach((problem) => {
    if (result[problem.category]) {
      result[problem.category].push(problem);
    } else {
      result[problem.category] = [problem];
    }
  });
  return result;
};
