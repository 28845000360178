import { MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { loginSwitch } from "src/store/recoil_state";

// TODO twitter toka nimo taio suru.
export const UserIcon = () => {
  const user = useRecoilValue(loginSwitch);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!user) {
    return null;
  }
  return (
    <>
      <img
        style={{
          borderRadius: "100%",
          width: 50,
          height: 50,
          cursor: "pointer",
          marginLeft: "auto",
        }}
        src={user?.photoURL ?? ""}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            const auth = getAuth();
            signOut(auth)
              .then(() => {
                console.log("Sign out");
              })
              .catch((error) => {
                console.error("Error signing out:", error);
              });
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
