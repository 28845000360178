import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useRecoilState } from "recoil";
import { NotFoundPage } from "src/components/pages/not_found/not_found_page";
import { SignInPage } from "src/components/pages/login/signin_page";
import { FirebaseUser } from "src/firebase/auth/user";
import { firebaseAuth } from "src/firebase/firebase";
import { loginSwitch } from "src/store/recoil_state";
import { MainPage } from "src/components/pages/main/main_page";

export const AppRoute: React.FC = () => {
  const [, setLoginState] = useRecoilState(loginSwitch);
  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      console.log("onAuthStateChanged", user?.toJSON());
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // TODO use type guard.
        setLoginState(user.toJSON() as FirebaseUser);
      } else {
        setLoginState(undefined);
      }
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/login" element={<SignInPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
