import { Button } from "@mui/material";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useModal } from "src/components/organisms/context_modal/context_modal";
import { Problem } from "src/components/pages/main/propblem";
import { ProblemID, useProblemCookie } from "src/core/problem";

export type ProblemPopupButtonProps = {
  problemID: ProblemID;
};

export const ProblemPopupButton = (props: ProblemPopupButtonProps) => {
  const modalController = useModal();
  const [cookies] = useProblemCookie(props.problemID);
  return (
    <Button
      variant={cookies?.status === "solved" ? "outlined" : "contained"}
      style={{ margin: "2px" }}
      onClick={() => {
        modalController.open(
          <Problem
            imagePath={`/images/problems/${props.problemID}.jpg`}
            problemID={props.problemID}
          />
        );
      }}
      key={props.problemID}
    >
      {props.problemID}
    </Button>
  );
};

import { CookieSetOptions } from "universal-cookie";

type CookieKey = "userName" | "age" | "family";
type UserCookie = {
  userName?: string;
  age?: number;
  family?: {
    father: string;
    mother: string;
  };
};

export const useUserCookie = (): [
  UserCookie,
  (value: UserCookie["userName"], options?: CookieSetOptions) => void,
  (value: UserCookie["age"], options?: CookieSetOptions) => void,
  (value: UserCookie["family"], options?: CookieSetOptions) => void
] => {
  // * when main page is loaded, it will be called with problemID undefined.
  const [cookies, setCookie, removeCookie, updateCookies] = useCookies<
    CookieKey,
    UserCookie
  >(["age", "family", "userName"]);

  const setUserNameCookie = useCallback(
    (value: UserCookie["userName"], options?: CookieSetOptions) => {
      setCookie("userName", value, options);
    },
    []
  );

  const setUserAgeCookie = useCallback(
    (value: UserCookie["age"], options?: CookieSetOptions) => {
      setCookie("age", value, options);
    },
    []
  );

  const setFamilyCookie = useCallback(
    (value: UserCookie["family"], options?: CookieSetOptions) => {
      setCookie("family", value, options);
    },
    []
  );

  return [cookies, setUserNameCookie, setUserAgeCookie, setFamilyCookie];
};
