import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "src/style/default_style.css";
import "src/style/google.css";

// please see https://swiperjs.com/react#usage
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ModalContext } from "src/components/organisms/context_modal/context_modal";
import { initializeFirebaseApp } from "src/firebase/firebase";
import { AppRoute } from "src/route";
import { CookiesProvider } from "react-cookie";
initializeFirebaseApp();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/**
 * @link https://mui.com/material-ui/react-modal/
 */

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <ModalContext>
          <BrowserRouter>
            <AppRoute />
          </BrowserRouter>
        </ModalContext>
      </CookiesProvider>
    </RecoilRoot>
  </React.StrictMode>
);
