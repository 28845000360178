import { ProblemDefinition } from "src/core/problem";

export const PROBLEMS: ProblemDefinition[] = [
  {
    answer: "えだまめ",
    category: "food",
    id: 1,
  },
  {
    answer: "のみかい",
    category: "speed",
    id: 2,
  },
  {
    answer: "おかき",
    category: "food",
    id: 3,
  },
  {
    answer: "おしるこ",
    category: "food",
    id: 4,
  },
  {
    answer: "おせんべい",
    category: "food",
    id: 5,
  },
  {
    answer: "おはぎ",
    category: "food",
    id: 6,
  },
  {
    answer: "おこわ",
    category: "food",
    id: 7,
  },
  {
    answer: "おかし",
    category: "food",
    id: 8,
  },
  {
    answer: "おかず",
    category: "food",
    id: 9,
  },
  {
    answer: "おやつ",
    category: "food",
    id: 10,
  },
  {
    answer: "さけ",
    category: "strong-alcohol",
    id: 100,
  },
];

