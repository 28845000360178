import { useRecoilValue } from "recoil";
import { UserIcon } from "src/components/organisms/user_icon/user_icon";
import { loginSwitch } from "src/store/recoil_state";

export const Header = () => {
  const user = useRecoilValue(loginSwitch);

  return (
    <div style={{ display: "flex", padding: "10px" }}>
      <div id="left">
        <h1>持ち帰り謎</h1>
      </div>
      <UserIcon />
    </div>
  );
};
