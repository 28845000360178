export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA-BmZ3OX3iqJcLSje-gO93g3GlTLsTAGk",
  authDomain: "izakaya-5432c.firebaseapp.com",
  projectId: "izakaya-5432c",
  storageBucket: "izakaya-5432c.appspot.com",
  messagingSenderId: "1042512669854",
  appId: "1:1042512669854:web:ffe928663826580a675f8f",
  measurementId: "G-RD713SXV5V",
  databaseURL: "https://izakaya-5432c-default-rtdb.asia-southeast1.firebasedatabase.app",
} as const;
