import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SignIn } from "src/components/pages/login/sign_in";
import { SignOut } from "src/components/pages/login/sign_out";
import { Header } from "src/components/templates/header/header";
import { loginSwitch } from "src/store/recoil_state";

const DesignWrapper = (props: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
      <div
        style={{
          // height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "20%",
            minWidth: "200px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
          className="space-10"
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export const SignInPage = () => {
  const [searchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get("token"), [searchParams]);
  const user = useRecoilValue(loginSwitch);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [user, navigate]);
  if (user) {
    return (
      <DesignWrapper>
        既にログインしています
        <SignOut />
      </DesignWrapper>
    );
  }

  if (token) {
    return (
      <DesignWrapper>
        <p>token: {token}</p>
        {/* <SignUp /> */}
        <SignIn />
        <SignOut />
      </DesignWrapper>
    );
  }

  return (
    <DesignWrapper>
      <SignIn />
      <SignOut />
    </DesignWrapper>
  );
};
