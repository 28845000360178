import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useProblemCookie } from "src/core/problem";

export type ProblemProps = {
  imagePath: string;
  problemID: number;
};

export const Problem = (props: ProblemProps) => {
  const [answer, setAnswer] = useState("");
  const [, setCookie] = useProblemCookie(props.problemID);
  return (
    <div
      style={{
        height: "80vh",
        width: "80vw",
        maxWidth: "1000px",
        display: "flex",
        padding: "10px",
        flexDirection: "column",
      }}
    >
      <h2>Problem {props.problemID}</h2>
      <div>
        <img src={props.imagePath} />
      </div>
      <div style={{ marginTop: "auto", display: "flex", alignItems: "center" }}>
        <TextField
          label="Answer"
          value={answer}
          placeholder="Answer"
          onChange={(event) => {
            setAnswer(event.target.value);
          }}
        />
        <Button
          variant="outlined"
          onClick={() => {
            // TODO if (answer === "correct")
            setCookie({
              status: "solved",
            });
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
