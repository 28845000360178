import { useNavigate } from "react-router-dom";
import { GoogleAuthButton } from "src/components/molecules/auth_button/google_auth_button";
import { loginWithGoogle } from "src/firebase/auth/auth";

export const SignIn = () => {
  const navigate = useNavigate();
  return (
    <>
      <GoogleAuthButton
        onClick={() => {
          loginWithGoogle().then((userCredential) => {
            navigate("/");
          });
        }}
      />
      {/* <Input placeholder="ユーザー名" />
      <Input placeholder="パスワード" /> */}
      {/* <TwitterAuthButton
        onClick={() => {
          loginWithTwitter().then((userCredential) => {
            navigate("/");
          });
        }}
      /> */}
    </>
  );
};
